import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Container } from '@mui/material'
import { ShowWarningModal, UseBlocker } from 'utils/useBlocker'

import { BattleShipModel } from 'models/BattleShipModel'
import { Game } from 'models/gameModels'

import { useIdeaStreakContext } from 'contexts/IdeaStreakProvider'
import { useModalContext } from 'contexts/ModalProvider'
import { useToastContext } from 'contexts/ToastProvider'

import {
	postBattleshipCompetitorResponse,
	postBattleshipPrompt,
	postBattleshipResponse,
	postBattleshipResponseToCompetitor,
} from 'services/completions.service'
import { postIdea } from 'services/ideas.service'

import BreadCrumbs from 'components/BreadCrumbs'
import CustomGameTitleBar from 'components/CustomGameTitleBar/CustomGameTitleBar'
import Hero from 'components/Hero/Hero'

import { alertTitleTextMap, defaultErrorMessage } from 'assets/alertText'
import { brainstormText } from 'assets/brainstormText'
import { SUBMITTED_IDEAS } from 'assets/routes'

import { GAME_STEP } from 'enums/GameStepEnum'
import { GAME_TYPE } from 'enums/GameTypeEnum'
import { ToastSeverity } from 'enums/ToastSeverityEnum'
import { BusinessTopicType, TopicType } from 'enums/TopicTypeEnum'

import IdeationSection from './components/IdeationSection/IdeationSection'
import InnovationSection from './components/InnovationSection/InnovationSection'
import PromptSection from './components/PromptSection/PromptSection'

export const TEST_ID = 'battle-ships'

const userMessage = {
	content: '',
	role: 'user',
}

const updateAIResponse = (content: string) => {
	return {
		content,
		role: 'assistant',
	}
}

function BattleShips({ game }: { game?: Game }) {
	const { setStreakBegun, updateStreak } = useIdeaStreakContext()
	const { showAlert } = useToastContext()
	const [submitIdeas, setSubmitIdeas] = useState(false)
	const [ideaPrompt, setIdeaPrompt] = useState(false)

	const [companyDisruptionIsLoading, setCompanyDisruptionIsLoading] =
		useState(false)
	const [exampleIdeasAreLoading, setExampleIdeasAreLoading] = useState(false)

	const [aiExampleVisible, setAiExampleVisible] = useState<
		Record<number, boolean>
	>({})

	const [step, setStep] = useState<GAME_STEP>(GAME_STEP.Step1)

	useEffect(() => {
		if (game) {
			setCompanyName(game.innovationCompany)
			setCompanyProblem(game.companyProblem)
			setTopic(game.innovationTopic)
			setGameId(game.gameId)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [game])

	const {
		companyName,
		setCompanyName,
		topic,
		setTopic,
		promptConversation,
		setPromptConversation,
		companyProblem,
		setCompanyProblem,
		currentPrompt,
		setCurrentPrompt,
		responseConversation,
		setResponseConversation,
		currentResponses,
		setCurrentResponses,
		authorIdeation,
		setAuthorIdeation,
		generateScenarioDisabled,
		generateNewScenarioDisabled,
		promptObject,
		resetSoft,
		requestObject,
		postObject,
		submitIdeasDisabled,
		reset,
		setGameId,
		termsAgreed,
		setTermsAgreed,
	} = BattleShipModel()

	const { showModal } = useModalContext()
	const fields = [authorIdeation]
	const blocker = UseBlocker(fields, submitIdeas)

	useEffect(() => {
		setIdeaPrompt(authorIdeation.length >= 8)
	}, [authorIdeation])

	useEffect(() => {
		if (!submitIdeas) {
			return ShowWarningModal(blocker, showModal)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [blocker])

	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			if (ideaPrompt) {
				event.preventDefault()
			}
		}

		if (ideaPrompt) {
			window.addEventListener('beforeunload', handleBeforeUnload)
		} else {
			window.removeEventListener('beforeunload', handleBeforeUnload)
		}

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload)
		}
	}, [ideaPrompt])

	const navigate = useNavigate()

	const reloadIdeas = async (newPrompt: string) => {
		setCurrentPrompt(newPrompt)
		setExampleIdeasAreLoading(true)
		await preFetchAiResponses(newPrompt)
		setExampleIdeasAreLoading(false)
	}

	const preFetchAiResponses = async (companyDisruption: string) => {
		let aiScenarioResponse = ''
		const props = {
			companyDisruption: '',
			aiScenarioResponse: '',
		}

		for (let i = 0; i < currentResponses.length; i++) {
			props.companyDisruption = companyDisruption
			if (i === 2) {
				props.aiScenarioResponse = aiScenarioResponse
			}
			const response = await handleAiResponseClick(
				i,
				false,
				false,
				companyDisruption
			)
			if (i === 1) {
				aiScenarioResponse = response
			}
		}
	}

	const refreshPrompt = async () => {
		if (
			step === GAME_STEP.Step2 &&
			!submitIdeas &&
			!!authorIdeation.every((ideation) => ideation.length)
		) {
			return ShowWarningModal(
				{
					state: 'blocked',
					location: {
						pathname: '',
						search: '',
						hash: '',
						state: null,
						key: 'kxdgy6c2',
					},
					reset: () => console.log('reset'),
					proceed: () => handleClick(),
				},
				showModal
			)
		}

		handleClick()
	}

	const handleClick = async () => {
		setCompanyDisruptionIsLoading(true)
		setExampleIdeasAreLoading(true)
		setAiExampleVisible({})
		resetSoft()

		try {
			// setup the conversation
			const prompt = promptObject()
			prompt.conversation = [...promptConversation, userMessage]
			setStep(GAME_STEP.Step2)
			const response = await postBattleshipPrompt({
				innovationTopic: prompt.topic,
				innovationCompany: prompt.companyName,
				conversation: prompt.conversation,
				companyProblem: prompt.companyProblem,
			})

			if (
				response.status === 200 &&
				response.data &&
				response.data.companyDisruption
			) {
				preFetchAiResponses(response.data.companyDisruption)
				const msg = JSON.stringify(response.data)
				// add response to state
				setPromptConversation((prevMessages) => [
					...prevMessages,
					userMessage,
					updateAIResponse(msg),
				])

				// setup the page correctly
				setCurrentPrompt(response.data.companyDisruption)
			}
		} catch (error) {
			console.error(error)
			if (showAlert) {
				showAlert(defaultErrorMessage)
			}
		} finally {
			setCompanyDisruptionIsLoading(false)
			setExampleIdeasAreLoading(false)
		}
	}

	const handleChange = (i: number) => (val: string) => {
		setAuthorIdeation((oldAuthorIdeation) => {
			return oldAuthorIdeation.map((cur, idx) => {
				if (i === idx) {
					return val
				} else {
					return cur
				}
			})
		})
	}

	const handleAiResponseClick = async (
		i: number,
		isUserInitiated = true,
		showToast = true,
		companyDisruption: string
	): Promise<string> => {
		let preFetchReturn: string = ''
		if (
			isUserInitiated &&
			i > 0 &&
			(!currentResponses[i - 1] || !aiExampleVisible[i - 1])
		)
			return ''
		if (isUserInitiated && !aiExampleVisible[i]) {
			setAiExampleVisible({ ...aiExampleVisible, [i]: true })
			return ''
		}
		try {
			// setup the conversation
			const prompt = requestObject(i)
			if (!isUserInitiated) {
				prompt.conversation = [userMessage]
			} else {
				prompt.conversation = [...responseConversation[i], userMessage]
			}

			let response = {
				status: 400,
				data: {
					competitorName: '',
					responses: [''],
				},
			}

			if (i === 0) {
				response = await postBattleshipResponse({
					innovationTopic: prompt.topic,
					innovationCompany: prompt.companyName,
					companyDisruption,
					conversation: prompt.conversation,
				})
			} else if (i === 1) {
				response = await postBattleshipCompetitorResponse({
					innovationCompany: prompt.companyName,
					companyDisruption,
					conversation: prompt.conversation,
				})
			} else if (i === 2) {
				response = await postBattleshipResponseToCompetitor({
					aiScenarioResponse: prompt.aiScenarioResponse,
					innovationCompany: prompt.companyName,
					companyDisruption,
					conversation: prompt.conversation,
				})
			}

			if (
				response.status === 200 &&
				response.data &&
				response.data.responses
			) {
				const responsesArr = response.data.responses
				preFetchReturn = responsesArr.join(' ') || ''
				const msg = JSON.stringify(response.data)

				// add response to state
				const entireResponse = [
					...responseConversation[i],
					userMessage,
					updateAIResponse(msg),
				]

				// store the entire conversation
				setResponseConversation((oldResponseConversation) => {
					return oldResponseConversation.map((cur, idx) => {
						if (i === idx) {
							return entireResponse
						} else {
							return cur
						}
					})
				})

				// update the current response
				setCurrentResponses((oldCurrentResponses) => {
					return oldCurrentResponses.map((cur, idx) => {
						if (i === idx) {
							return responsesArr
						} else {
							return cur
						}
					})
				})
			} else {
				throw Error(
					`response received ${JSON.stringify(response.data)}`
				)
			}
		} catch (error) {
			console.error(error)
			if (showToast && showAlert) {
				showAlert(defaultErrorMessage)
			}
		}
		return preFetchReturn
	}

	const handleSubmitIdeas = async () => {
		setCompanyDisruptionIsLoading(true)
		setSubmitIdeas(true)

		try {
			const response = await postIdea(postObject())

			if (showAlert) {
				showAlert({
					title:
						response.status === 200
							? alertTitleTextMap.submitIdea.title
							: alertTitleTextMap.submitIdeaError.title,
					severity:
						response.status === 200
							? ToastSeverity.SUCCESS
							: ToastSeverity.ERROR,
				})
			}
		} catch (error) {
			console.error(error)
			if (showAlert) {
				showAlert(defaultErrorMessage)
			}
		} finally {
			setCompanyDisruptionIsLoading(false)

			// need to reset the model
			reset()

			window.scrollTo({
				top: 0,
			})
			await handleClick()

			setStreakBegun(true)
			updateStreak()
		}
	}

	const viewSubmittedIdeas = () => {
		window.scrollTo(0, 0)
		navigate(SUBMITTED_IDEAS)
	}

	const resetAllField = () => {
		setCompanyName('')
		setTopic('')
		setCompanyProblem('')
	}

	return (
		<Box>
			<Hero
				step={step}
				gameTypeEnum={GAME_TYPE.BATTLE_SHIPS}
				innovationCompany={companyName}
				innovationTopic={topic as TopicType}
				companyProblem={companyProblem}
				isLoading={companyDisruptionIsLoading}
				setInnovationCompany={setCompanyName}
				setInnovationTopic={setTopic}
				setCompanyProblem={setCompanyProblem}
				createPrompt={handleClick}
				hideEdit={Boolean(game && (game.gameTitle || game.endTime))}
			/>
			<Container
				data-testid={`${TEST_ID}-container`}
				maxWidth={step === GAME_STEP.Step1 ? 'md' : 'lg'}
				sx={{
					paddingTop: '3rem',
					paddingBottom: '3rem',
					paddingX: '2rem',
					maxWidth: '1440px',
				}}
			>
				<Box paddingBottom={'2rem'}>
					<BreadCrumbs
						breadcrumbs={[
							{
								label: '1. INNOVATION TOPIC',
								active: step === GAME_STEP.Step1,
								onClick:
									step !== GAME_STEP.Step1
										? () => setStep(GAME_STEP.Step1)
										: undefined,
							},
							{
								label: '2. GENAI PROMPTS & 3. IDEATION',
								active: step !== GAME_STEP.Step1,
							},
						]}
					/>
				</Box>
				{game && (game.gameTitle || game.endTime) && (
					<CustomGameTitleBar game={game} />
				)}
				{step === GAME_STEP.Step1 && (
					<InnovationSection
						topic={topic as BusinessTopicType}
						companyName={companyName}
						setCompanyName={setCompanyName}
						companyRisk={companyProblem}
						setCompanyRisk={setCompanyProblem}
						setTopic={setTopic}
						createPrompt={handleClick}
						resetAll={resetAllField}
						generateScenarioDisabled={generateScenarioDisabled}
						isLoading={companyDisruptionIsLoading}
						editable={
							game
								? game.gameTitle === '' && game.endTime === ''
								: true
						}
					/>
				)}
				{step === GAME_STEP.Step2 && (
					<>
						<PromptSection
							prompt={currentPrompt}
							isLoading={companyDisruptionIsLoading}
							handleClick={refreshPrompt}
							generateNewScenarioDisabled={
								generateNewScenarioDisabled
							}
							reloadIdeas={reloadIdeas}
						/>
						<IdeationSection
							gameTypeEnum={GAME_TYPE.BATTLE_SHIPS}
							isLoading={exampleIdeasAreLoading}
							handleSubmitIdeas={handleSubmitIdeas}
							submitIdeasDisabled={submitIdeasDisabled}
							currentResponses={currentResponses}
							authorIdeation={authorIdeation}
							handleChange={handleChange}
							viewSubmittedIdeas={viewSubmittedIdeas}
							cautionText={
								game?.gameTitle || game?.endTime
									? brainstormText.global.alternateWarningText
									: brainstormText.global.warningText
							}
							termsAgreed={termsAgreed}
							setTermsAgreed={setTermsAgreed}
							gameId={game?.gameId}
						/>
					</>
				)}
			</Container>
		</Box>
	)
}

export default BattleShips
