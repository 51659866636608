/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react'

import {
	AuthTokens,
	ConfirmResetPasswordInput,
	type ResetPasswordOutput,
	confirmResetPassword,
	confirmSignIn,
	fetchAuthSession,
	getCurrentUser,
	resetPassword,
	signIn,
	signInWithRedirect,
	signOut,
} from 'aws-amplify/auth'
import { Hub } from 'aws-amplify/utils'
import { navigateToRedirectUrl } from 'utils/authUtils'

import { LOCAL_STORAGE_KEYS } from 'assets/constants'

import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner'
import {
	AWS_IDENTITY_PROVIDER,
	GUEST_PASSWORD,
	GUEST_USERNAME,
} from '../config'

export interface user {
	email: string | null
	lastName: string
	name: string
	session?: AuthTokens
}

export interface IAuthContextType {
	guestSignIn: () => Promise<void>
	handleConfirmResetPassword: (
		{ username, confirmationCode, newPassword }: ConfirmResetPasswordInput,
		setError: (val: boolean) => void
	) => Promise<boolean>
	handleResetPassword: (
		email: string,
		setError: (val: boolean) => void
	) => Promise<void>
	isAuthenticated: boolean
	isAuthenticating: boolean
	ssoSignIn: () => Promise<void>
	user: user
	userSignIn: (
		email: string,
		password: string,
		setError: (val: boolean) => void
	) => Promise<void>
	userSignOut: () => Promise<void>
	userSignUp: (
		name: string,
		email: string,
		tempPassword: string,
		password: string,
		setError: (val: boolean) => void
	) => Promise<void>
}

// Create a context object
export const AuthContext = React.createContext<IAuthContextType>({
	user: {
		email: null,
		name: 'login',
		lastName: '',
		session: undefined,
	},
	isAuthenticated: false,
	isAuthenticating: true,
	guestSignIn: async () => {},
	userSignIn: async () => {},
	userSignUp: async () => {},
	userSignOut: async () => {},
	ssoSignIn: async () => {},
	handleResetPassword: async () => {},
	handleConfirmResetPassword: async () => {
		return false
	},
})

interface IAuthProviderProps {
	children: React.ReactNode
}

// Create a provider for components to consume and subscribe to changes
export const AuthProvider = ({ children }: IAuthProviderProps) => {
	const [user, setUser] = useState<user>({
		email: null,
		lastName: '',
		name: 'login',
		session: undefined,
	})
	const [isAuthenticating, setIsAuthenticating] = useState(true)

	/**
	 * fetch currently logged-in user using AWS Auth library
	 * @returns {Promise<void>}
	 */
	const fetchAuthUser = async () => {
		try {
			await getCurrentUser()

			const { tokens: session } = await fetchAuthSession()
			const email = String(
				session?.idToken?.payload.email ?? 'guest@guest.com'
			)
			const name = String(session?.idToken?.payload.given_name ?? 'Guest')
			const lastName = String(
				session?.idToken?.payload.family_name ?? 'Guest'
			)

			const fetchedUser: user = {
				email,
				lastName,
				name,
				session,
			}
			setIsAuthenticating(false)
			setUser(fetchedUser)
		} catch (err) {
			setIsAuthenticating(false)
			setUser({
				email: null,
				lastName: '',
				name: 'login',
				session: undefined,
			})
		}
	}

	useEffect(() => {
		fetchAuthUser()

		Hub.listen('auth', ({ payload }) => {
			switch (payload.event) {
				case 'signedIn':
					console.log('user have been signedIn successfully.')
					navigateToRedirectUrl()
					break
				case 'signedOut':
					console.log('user have been signedOut successfully.')
					break
				case 'tokenRefresh':
					console.log('auth tokens have been refreshed.')
					break
				case 'tokenRefresh_failure':
					console.log('failure while refreshing auth tokens.')
					break
				case 'signInWithRedirect':
					console.log(
						'signInWithRedirect API has successfully been resolved.'
					)
					break
				case 'signInWithRedirect_failure':
					console.log(
						'failure while trying to resolve signInWithRedirect API.'
					)
					break
				case 'customOAuthState':
					console.info('custom state returned from CognitoHosted UI')
					break
			}
		})
	}, [])

	/**
	 * log guest in
	 */
	const guestSignIn = async () => {
		if (GUEST_USERNAME !== undefined) {
			const { isSignedIn, nextStep } = await signIn({
				username: GUEST_USERNAME,
				password: GUEST_PASSWORD,
			})
		} else {
			console.error('Guest Username is undefined')
		}
	}
	/**
	 * log verified user in
	 */
	const userSignIn = async (
		email: string,
		password: string,
		setError: (val: boolean) => void
	) => {
		const username = email.substring(0, email.indexOf('@'))
		if (
			(username.length !== 0 || username !== undefined) &&
			(password.length !== 0 || password !== undefined)
		) {
			try {
				const signInRes = await signIn({
					username,
					password,
				})
			} catch (e) {
				console.error(e)
				setError(true)
			}
		} else {
			console.error("User's username or password is undefined or invalid")
		}
	}

	/**
	 * Verify new user
	 */
	const userSignUp = async (
		name: string,
		email: string,
		tempPassword: string,
		password: string,
		setError: (val: boolean) => void
	) => {
		const username = email.substring(0, email.indexOf('@'))
		if (
			(username.length !== 0 || username !== undefined) &&
			(password.length !== 0 || password !== undefined)
		) {
			try {
				const { nextStep } = await signIn({
					username,
					password: tempPassword,
				})

				if (
					nextStep.signInStep ===
					'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
				) {
					/**
					 * "pathname" is "/published-games/<GAME_ID>" since this is called
					 * within a modal thats opened within the published game component
					 */
					localStorage.setItem(
						LOCAL_STORAGE_KEYS.REDIRECT_PATH,
						`${window.location.pathname}?new_user=true`
					)
					await confirmSignIn({
						challengeResponse: password,
						options: {
							userAttributes: {
								given_name: name,
							},
						},
					})
				}
			} catch (e) {
				console.error(e)
				setError(true)
			}
		} else {
			console.error("User's username or password is undefined or invalid")
		}
	}
	/**
	 * logout user
	 */
	const userSignOut = async () => signOut()

	/**
	 * sso sign in
	 */
	const ssoSignIn = async () => {
		if (AWS_IDENTITY_PROVIDER !== undefined) {
			await signInWithRedirect({
				provider: { custom: AWS_IDENTITY_PROVIDER },
			})
		} else {
			console.error('Missing AWS Provider')
		}
	}

	const handleResetPassword = async (
		email: string,
		setError: (val: boolean) => void
	) => {
		try {
			const username = email.split('@')[0]
			const response = await resetPassword({ username })
		} catch (e) {
			console.log(e)
			setError(true)
		}
	}

	const handleConfirmResetPassword = async (
		{ username, confirmationCode, newPassword }: ConfirmResetPasswordInput,
		setError: (val: boolean) => void
	) => {
		try {
			await confirmResetPassword({
				username,
				confirmationCode,
				newPassword,
			})
			return true
		} catch (e) {
			console.error(e)
			setError(true)
			return false
		}
	}

	const value: IAuthContextType = {
		user,
		isAuthenticated: !!user && !!user.session,
		isAuthenticating,
		guestSignIn,
		userSignIn,
		userSignUp,
		userSignOut,
		ssoSignIn,
		handleResetPassword,
		handleConfirmResetPassword,
	}

	if (isAuthenticating) {
		return <LoadingSpinner />
	}

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthProvider
