import { palette } from 'theme/palette'

import { GAME_TYPE } from 'enums/GameTypeEnum'

const styles = {
	textField: {
		'& .MuiOutlinedInput-root': {
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'black',
				opacity: '23%',
			},
		},
	},
	inputLabel: {
		color: 'black',
		opacity: '23%',
	},
	linkModal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '80%',
		maxWidth: '822px',
		bgcolor: 'background.paper',
		border: '2px transparent',
		borderRadius: '30px',
		boxShadow: 24,
		p: 4,
	},
}

const setChipColor = (gameTypeValue: number | undefined) => {
	if (!gameTypeValue) {
		return '#fff'
	}
	if (gameTypeValue === GAME_TYPE.BATTLE_SHIPS.value) {
		return palette.chipColor.battleships.main
	}
	if (gameTypeValue === GAME_TYPE.DRESS_UP.value) {
		return palette.chipColor.dressup.main
	}
	if (gameTypeValue === GAME_TYPE.TEETER_TOTTER.value) {
		return palette.chipColor.teeterTotter.main
	}
	return '#fff'
}

export { styles, setChipColor }
