import React, { useEffect, useState } from 'react'

import { Box, Container } from '@mui/material'
import { ShowWarningModal, UseBlocker } from 'utils/useBlocker'

import { ConnectFourModel } from 'models/ConnectFourModel'
import { Game } from 'models/gameModels'

import { useIdeaStreakContext } from 'contexts/IdeaStreakProvider'
import { useModalContext } from 'contexts/ModalProvider'
import { useToastContext } from 'contexts/ToastProvider'

import { postConnectFourResponse } from 'services/completions.service'
import { getRandomPrompts, postIdea } from 'services/ideas.service'

import BreadCrumbs from 'components/BreadCrumbs'
import CustomGameTitleBar from 'components/CustomGameTitleBar/CustomGameTitleBar'
import Hero from 'components/Hero'

import { alertTitleTextMap, defaultErrorMessage } from 'assets/alertText'
import { brainstormText } from 'assets/brainstormText'

import { GAME_STEP } from 'enums/GameStepEnum'
import { GAME_TYPE } from 'enums/GameTypeEnum'
import { ToastSeverity } from 'enums/ToastSeverityEnum'

import Section1Component from './components/Section1/'
import Section2Component from './components/Section2/'
import Section3Component from './components/Section3/'

const userMessage = {
	content: '',
	role: 'user',
}

export const TEST_ID = 'leap-frog'

const LeapFrog = ({ game }: { game?: Game }) => {
	const [submitIdeas, setSubmitIdeas] = useState(false)
	const [ideaPrompt, setIdeaPrompt] = useState(false)

	const {
		companyName,
		setCompanyName,
		companyProblem,
		setCompanyProblem,
		currentPrompts,
		authorIdeation,
		setAuthorIdeation,
		responseConversation,
		setResponseConversation,
		currentResponses,
		setCurrentResponses,
		generatePromptsDisabled,
		generateNewPromptsDisabled,
		setPromptResponse,
		responseToJSON,
		buttonDisabled,
		gameToJSON,
		reset,
		aiButtonDisabled,
		resetSoft,
		setGameId,
		getRandomPromptsQueryObj,
		setPreviousPrompts,
	} = ConnectFourModel()

	useEffect(() => {
		if (game) {
			setCompanyName(game.innovationCompany)
			setCompanyProblem(game.companyProblem)
			setGameId(game.gameId)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [game])

	const { showModal } = useModalContext()
	const fields = [authorIdeation]
	const blocker = UseBlocker(fields, submitIdeas)

	useEffect(() => {
		setIdeaPrompt(authorIdeation.length >= 8)
	}, [authorIdeation])

	useEffect(() => {
		if (!submitIdeas) {
			return ShowWarningModal(blocker, showModal)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [blocker])

	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			if (ideaPrompt) {
				event.preventDefault()
			}
		}

		if (ideaPrompt) {
			window.addEventListener('beforeunload', handleBeforeUnload)
		} else {
			window.removeEventListener('beforeunload', handleBeforeUnload)
		}

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload)
		}
	}, [ideaPrompt])

	const { setStreakBegun, updateStreak } = useIdeaStreakContext()
	const { showAlert } = useToastContext()

	const [isLoading, setIsLoading] = useState(false)
	const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false)
	const [isAiLoading, setIsAiLoading] = useState(false)
	const [aiExampleVisible, setAiExampleVisible] = useState(false)
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [step, setStep] = useState<GAME_STEP>(GAME_STEP.Step1)

	const handleSubmitIdeas = async () => {
		setIsSubmitButtonLoading(true)
		setIsLoading(true)
		setSubmitIdeas(true)

		try {
			const response = await postIdea(gameToJSON())

			if (showAlert && step !== GAME_STEP.Step1) {
				showAlert({
					title:
						response.status === 200
							? alertTitleTextMap.submitIdea.title
							: alertTitleTextMap.submitIdea.text,
					severity:
						response.status === 200
							? ToastSeverity.SUCCESS
							: ToastSeverity.ERROR,
				})
			}
		} catch (error) {
			console.error(error)
			if (showAlert) {
				showAlert(defaultErrorMessage)
			}
		} finally {
			setIsLoading(false)
			setIsSubmitButtonLoading(false)
			// need to reset the model
			reset()

			window.scrollTo({
				top: 0,
			})

			setStreakBegun(true)
			updateStreak()

			await handleRandomPrompts()
		}
	}

	const refreshPrompt = async () => {
		if (
			step === GAME_STEP.Step2 &&
			!submitIdeas &&
			!!authorIdeation.length
		) {
			return ShowWarningModal(
				{
					state: 'blocked',
					location: {
						pathname: '',
						search: '',
						hash: '',
						state: null,
						key: 'kxdgy6c2',
					},
					reset: () => console.log('reset'),
					proceed: () => handleRandomPrompts(),
				},
				showModal
			)
		}

		handleRandomPrompts()
	}

	const handleRandomPrompts = async () => {
		try {
			resetSoft()
			setAiExampleVisible(false)
			setIsLoading(true)

			const response = await getRandomPrompts(getRandomPromptsQueryObj())

			if (response.status === 200 && response.data?.prompts) {
				setStep(GAME_STEP.Step2)
				handleAiResponseClick({
					currentPrompt: response.data?.prompts.join(', '),
					isUserInitiated: false,
				})
				if (response.data?.resetPreviousPrompts) {
					setPreviousPrompts([])
				}
				setPromptResponse(response.data.prompts)
			} else {
				throw Error(`response received ${response.data}`)
			}
		} catch (error) {
			console.error(error)

			if (showAlert) {
				showAlert({
					severity: ToastSeverity.ERROR,
					title: defaultErrorMessage.title,
					bodyText: defaultErrorMessage.bodyText,
				})
			}
		} finally {
			setIsLoading(false)
		}
	}

	const updateAIResponse = (content: string) => {
		return {
			content,
			role: 'assistant',
		}
	}

	const buildCurrentResponse = (
		response: Record<string, { ideas: string[]; key: string }[]>
	) => {
		// create a json object from the response
		const items: { ideas: string[]; title: string }[] = []

		response.responses.forEach((entry) => {
			const item = {
				title: entry.key,
				ideas: [...entry.ideas],
			}

			items.push(item)
		})

		return items
	}
	const handleAiResponseClick = async ({
		currentPrompt = '',
		isUserInitiated = true,
	} = {}) => {
		try {
			if (!aiButtonDisabled() && !isLoading && !isAiLoading) {
				if (isUserInitiated && !aiExampleVisible) {
					setAiExampleVisible(true)
					return
				}

				setIsAiLoading(true)

				// setup the conversation
				const prompt = responseToJSON()
				prompt.conversation = [...responseConversation, userMessage]
				if (currentPrompt) {
					prompt.currentPrompt = currentPrompt
				}

				const response = await postConnectFourResponse(prompt)

				if (response.status === 200 && response.data) {
					const msg = JSON.stringify(response.data)

					// add response to state
					setResponseConversation((prevMessages) => [
						...prevMessages,
						userMessage,
						updateAIResponse(msg),
					])
					setCurrentResponses(buildCurrentResponse(response.data))
				} else {
					throw Error(`response received ${response.data}`)
				}
			}
		} catch (error) {
			console.error(error)
			if (showAlert) {
				showAlert(defaultErrorMessage)
			}
		} finally {
			setIsAiLoading(false)
		}
	}

	const handleUserResponse = async (user: string) => {
		setAuthorIdeation(user)
	}

	// const clearAiResponse = async () => {
	// 	setCurrentResponses([])
	// }

	const resetAllField = () => {
		setCompanyName('')
		setCompanyProblem('')
	}

	return (
		<Box>
			<Hero
				step={step}
				gameTypeEnum={GAME_TYPE.LEAP_FROG}
				innovationCompany={companyName}
				companyProblem={companyProblem}
				isLoading={isLoading}
				setCompanyProblem={setCompanyProblem}
				setInnovationCompany={setCompanyName}
				createPrompt={handleRandomPrompts}
				hideEdit={Boolean(game && (game.gameTitle || game.endTime))}
			/>
			<Container
				maxWidth="md"
				sx={{
					paddingTop: '3rem',
					paddingBottom: '3rem',
					paddingX: '3rem',
				}}
			>
				<Box data-testid={`${TEST_ID}__container`}>
					<Box paddingBottom={'2rem'}>
						<BreadCrumbs
							breadcrumbs={[
								{
									label: '1. INNOVATION TOPIC',
									active: step === GAME_STEP.Step1,
									onClick:
										step !== GAME_STEP.Step1
											? () => setStep(GAME_STEP.Step1)
											: undefined,
								},
								{
									label: '2. GENAI PROMPTS & 3. IDEATION',
									active: step !== GAME_STEP.Step1,
								},
							]}
						/>
					</Box>
					{game && (game.gameTitle || game.endTime) && (
						<CustomGameTitleBar game={game} />
					)}
					{step === GAME_STEP.Step1 && (
						// TODO: Have this grow to fill the entire screen
						<Section1Component
							isLoading={isLoading}
							innovationCompany={companyName}
							setInnovationCompany={setCompanyName}
							companyProblem={companyProblem}
							setCompanyProblem={setCompanyProblem}
							generateScenarioDisabled={generatePromptsDisabled}
							resetAllField={resetAllField}
							createPrompt={handleRandomPrompts}
							editable={
								game
									? game.gameTitle === '' &&
										game.endTime === ''
									: true
							}
						/>
					)}
					{step === GAME_STEP.Step2 && (
						<>
							<Section2Component
								isLoading={isLoading}
								isAiLoading={isAiLoading}
								currentPrompts={currentPrompts}
								currentResponses={currentResponses}
								generatePromptsDisabled={
									generateNewPromptsDisabled
								}
								handleRandomPrompts={refreshPrompt}
								handleAiResponseClick={handleAiResponseClick}
								popperContent={brainstormText.leapFrog.popper}
							/>
							<Section3Component
								isLoading={isLoading}
								isAiLoading={isAiLoading}
								authorIdeation={authorIdeation}
								handleUserResponse={handleUserResponse}
								buttonDisabled={buttonDisabled}
								handleSubmitIdeas={handleSubmitIdeas}
								isSubmitButtonLoading={isSubmitButtonLoading}
								cautionText={
									game?.gameTitle || game?.endTime
										? brainstormText.global
												.alternateWarningText
										: brainstormText.global.warningText
								}
							/>
						</>
					)}
				</Box>
			</Container>
		</Box>
	)
}

export default LeapFrog
