import { ToastSeverity } from 'enums/ToastSeverityEnum'

const alertTitleTextMap = {
	submitIdea: {
		title: 'Your ideas have been successfully submitted!',
		text: '',
	},
	submitIdeaError: {
		title: 'Uh oh, error submitting your ideas',
		text: "This isn't your fault, try again later",
	},
	deleteIdea: {
		title: 'Idea has been deleted!',
		text: '',
	},
	editIdea: {
		title: 'Idea has been updated!',
		text: '',
	},
	genAiError: {
		title: 'Whoops, error generating a scenario',
		text: 'GenAI can be finicky, please try again',
	},
	createGame: {
		title: 'Game Successfully Created',
		text: '',
	},
	deleteGame: {
		title: 'game deleted.',
		text: '',
	},
	deleteUser: {
		title: 'We have received your account deletion request',
		text: '',
	},
	deleteUserError: {
		title: 'error processing deletion request',
		text: 'check with administrator',
	},
	updateGame: {
		title: 'game updated.',
		text: '',
	},
	copyGameLink: {
		title: 'Game link copied.',
		text: '',
	},
	sendGameInvite: {
		title: 'Email invitation(s) successfully sent',
		text: '',
	},
	resetPassword: {
		title: 'Password was reset successfully',
		text: '',
	},
	editPrompt: {
		title: 'GenAI prompt updated!',
		text: '',
	},
	updateUser: {
		title: 'Changes successfully changed',
		text: '',
	},
	defaultError: {
		title: `This feature isn't working right now`,
		text: 'Please try again later.',
	},
	copyResponse: {
		title: 'Response copied to your clipboard',
		text: '',
	},
}

const defaultErrorMessage = {
	bodyText: alertTitleTextMap.defaultError.text,
	severity: ToastSeverity.ERROR,
	title: alertTitleTextMap.defaultError.title,
}

export { alertTitleTextMap, defaultErrorMessage }
