import React from 'react'

import LockIcon from '@mui/icons-material/Lock'
import { Button, InputAdornment, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'

import { BaseTextField } from 'components/Form'
import TopicChips from 'components/Hero/TopicChips'

import { TopicType } from 'enums/TopicTypeEnum'

import { styles } from './Section1.styles'

export const TEST_ID = 'dress-up-section1'

export type Props = {
	companyProblem: string
	createPrompt: () => void
	editable: boolean
	generateScenarioDisabled: () => boolean
	innovationCompany: string
	innovationTopic: TopicType
	isLoading: boolean
	resetAllField: () => void
	setCompanyProblem: (val: string) => void
	setInnovationCompany: (val: string) => void
	setInnovationTopic: (val: TopicType) => void
}

const Section1 = ({
	innovationCompany,
	setInnovationCompany,
	innovationTopic,
	setInnovationTopic,
	companyProblem,
	setCompanyProblem,
	generateScenarioDisabled,
	resetAllField,
	isLoading,
	createPrompt,
	editable,
}: Props) => {
	const theme = useTheme()

	return (
		<>
			<Typography marginY={'2rem'} fontSize={'1.4rem'}>
				1. INNOVATION TOPIC
			</Typography>
			<Box display={'flex'}>
				<Box marginRight={'2rem'} sx={styles.sideBar} />
				<Box
					display={'flex'}
					flexDirection={'column'}
					rowGap={'3rem'}
					flexGrow="1"
				>
					<Typography color="black">
						What company are you brainstorming about today?
					</Typography>
					<BaseTextField
						disabled={!editable}
						InputLabelProps={{ style: styles.inputLabel }}
						variant="outlined"
						data-testid={`${TEST_ID}__company-name-input`}
						label="Enter company name here."
						value={innovationCompany}
						onChangeValue={(val) => setInnovationCompany(val)}
						required
						size="medium"
						InputProps={{
							endAdornment: !editable && (
								<InputAdornment position="start">
									<LockIcon />
								</InputAdornment>
							),
						}}
						sx={{
							'& .MuiInputBase-input': {
								boxSizing: 'content-box',
								height: '100%',
							},
							...styles.textField,
						}}
					/>
					<Typography color="black">
						What business topic do you want to focus on?
					</Typography>
					<TopicChips
						color={theme.palette.chipColor.dressup.main as string}
						innovationTopic={innovationTopic}
						setInnovationTopic={setInnovationTopic}
						editable={editable}
					/>
					<Typography color="black" sx={{ opacity: '87%' }}>
						Optional: Would you like to focus on a specific part of
						your chosen business function?
					</Typography>
					<BaseTextField
						disabled={!editable}
						InputLabelProps={{
							style: styles.inputLabel,
						}}
						variant="outlined"
						data-testid={`${TEST_ID}__company-problem-input`}
						label="Summarize in 5-10 words"
						onChangeValue={(val) => setCompanyProblem(val)}
						value={companyProblem}
						size="medium"
						InputProps={{
							endAdornment: !editable && (
								<InputAdornment position="start">
									<LockIcon />
								</InputAdornment>
							),
						}}
						sx={{
							'& .MuiInputBase-input': {
								boxSizing: 'content-box',
								height: '100%',
							},
							...styles.textField,
						}}
					/>
					<Box
						display="flex"
						sx={{
							flexDirection: {
								sm: 'row',
							},
						}}
						gap="2rem"
					>
						<Button
							color="secondary"
							variant="contained"
							data-testid={`${TEST_ID}__create-prompt-button`}
							disabled={generateScenarioDisabled() || isLoading}
							onClick={createPrompt}
						>
							CREATE A PROMPT
						</Button>
						<Button
							disabled={!editable}
							variant="text"
							color="secondary"
							data-testid={`${TEST_ID}__reset-all-button`}
							onClick={resetAllField}
						>
							RESET ALL FIELDS
						</Button>
					</Box>
				</Box>
			</Box>
		</>
	)
}
export default Section1
