export const styles = {
	bannerSection: {
		display: 'flex',
		flexDirection: 'row-reverse',
		alignItems: 'center',
		justifyContent: 'space-between',
		minHeight: '206px',
		background:
			'linear-gradient(90deg, rgba(91, 172, 233, 0.6) 0%, rgba(91, 172, 233, 0.4) 100%)',
		paddingX: '96px',
		paddingY: '32px',
		gap: '24px',
		'@media (max-width: 834px)': {
			flexDirection: 'column',
			alignItems: 'flex-start',
			paddingX: '64px',
			paddingY: '16px',
			minHeight: 'auto',
		},
		'@media (max-width: 390px)': {
			paddingX: '16px',
		},
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: '16px',
	},
	header: {
		fontWeight: '300',
		fontSize: '60px',
		lineHeight: '72px',
		letterSpacing: '-0.5px',
		color: 'rgba(0, 0, 0, 0.87)',
	},
	subheader: {
		fontWeight: '400',
		fontSize: '20px',
		lineHeight: '32px',
		letterSpacing: '0.15px',
		color: 'rgba(0, 0, 0, 0.87)',
	},
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: '24px',
		'@media (max-width: 600px)': {
			flexDirection: 'column',
			gap: '2rem',
		},
	},
	inputLabel: {
		color: 'rgba(0, 0, 0, 0.23)',
	},
	currentProfileSection: {
		display: 'flex',
		justifyContent: 'center',
		gap: '32px',
		maxWidth: '100%',
		minHeight: '221px',
		marginTop: '40px',
		flexWrap: 'wrap',
		'@media (max-width: 600px)': {
			flexDirection: 'column',
			paddingX: '16px',
			paddingY: '16px',
			minHeight: 'auto',
		},
	},
	profileContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: '16px',
		alignItems: 'center',
		position: 'relative',
	},
	profileImageContainer: {
		position: 'relative',
		width: '150px',
		height: '150px',
		// Replaced 'flex' by 'none' to hide the photo in profile
		display: 'none',
		alignItems: 'center',
		justifyContent: 'center',
		overflow: 'hidden',
	},
	profileImage: {
		width: '150px',
		height: '150px',
		objectFit: 'cover',
	},
	editIcon: {
		color: '#fff',
		backgroundColor: 'rgba(69, 101, 123, 1)',
		position: 'absolute',
		padding: '4px',
		bottom: '8px',
		right: '8px',
		'&:hover': {
			backgroundColor: 'rgba(69, 101, 123, 1)',
		},
	},
	currentNameLabel: {
		fontSize: '24px',
		lineHeight: '32.02px',
		color: 'rgba(69, 101, 123, 1)',
	},
	currentEmailLabel: {
		fontSize: '16px',
		lineHeight: '8px',
		letterSpacing: '0.15px',
		color: 'rgba(0, 0, 0, 0.6)',
	},
	profileInputSection: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		gap: '32px',
		maxWidth: '822px',
		minHeight: '828px',
		margin: '0 auto',
		marginBottom: '100px',
		paddingRight: '32px',
		'@media (max-width: 600px)': {
			flexDirection: 'column',
			alignItems: 'flex-start',
			paddingX: '16px',
			paddingY: '16px',
			minHeight: 'auto',
			maxWidth: '100%',
		},
	},
	formContainer: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: '3rem',
		flexGrow: 1,
		width: '100%',
	},
	nameInputContainer: {
		display: 'flex',
		flexDirection: 'row',
		gap: '32px',
		flexWrap: 'wrap',
		width: '100%',
		'@media (max-width: 600px)': {
			flexDirection: 'column',
		},
	},
	nameInputRow: {
		display: 'flex',
		flexDirection: 'column',
		gap: '32px',
		flex: '1 1 45%',
		minWidth: '0',
		width: '100%',
		'@media (max-width: 600px)': {
			width: '100%',
		},
	},
	textField: {
		width: '100%',

		'& .MuiOutlinedInput-root': {
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'black',
				opacity: '23%',
			},
		},
		'& .MuiInputBase-input': {
			boxSizing: 'content-box',
			height: '100%',
		},
	},
	sideBar: {
		display: { xs: 'none', sm: 'block' },
		minWidth: '4px',
		minHeight: '877px',
		marginTop: '50px',
		background:
			'linear-gradient(180deg, rgba(114, 164, 239, 1) 0%, rgba(18, 187, 241, 0.51) 100%)',
	},
	label: {
		color: 'rgba(0, 0, 0, 0.87)',
		fontSize: '20px',
		lineHeight: '26.68px',
	},
	caption: {
		color: 'rgba(0, 0, 0, 0.6)',
		fontSize: '12px',
		lineHeight: '19.92px',
		letterSpacing: '0.4px',
		width: '100%',
		maxWidth: '75vw',
	},
	overline: {
		fontSize: '12px',
		lineHeight: '31.92px',
		letterSpacing: '1px',
	},
	button: {
		padding: '8px 22px',
		maxWidth: 'fit-content',
		fontWeight: '500',
		fontSize: '15px',
		lineHeight: '26px',
		letterSpacing: '0.46px',
		margin: '8px 0px',
	},
}
